import './Header.scss';
import React from 'react';
import { AppBar, Toolbar, Typography } from '@material-ui/core';

export default function Header() {
	return (
		<AppBar id="appBar" position="sticky" color="primary">
			<Toolbar>
				<Typography variant="h5" color="inherit">Patrick's Blog</Typography>
			</Toolbar>
		</AppBar>
	);
}
