import React from 'react';
import Header from './header/Header';
import './App.scss';
import ReactTutorialPage from './react-tutorial-page/ReactTutorialPage';

function App() {
  return (
    <div className="app">
      <Header />
      <div className="app-content">
        <ReactTutorialPage />
      </div>
    </div>
  );
}

export default App;
