import React from 'react';
import ExtractComponentTutorial from './state-tutorial/ExtractComponentTutorial';
import StateTutorial from './state-tutorial/StateTutorial';

export default function ReactTutorialPage() {

	return (
		<React.Fragment>
			<ExtractComponentTutorial />
			<StateTutorial />
		</React.Fragment>
	)

}
