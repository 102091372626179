import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { stackoverflowLight as mystyle } from 'react-syntax-highlighter/dist/esm/styles/hljs';

const appWithState: string =
`class App extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLandingPage: true
    };
  }

  // ...render()
}`

const renderConditionally: string =
`render() {
  let page;
  if (this.state.isLandingPage) {
    page = <LandingPage />;
  else {
    page = <DataCollectionPage />;
  }
  
  return (
    <div>
      {page}
    </div>
  );
}`

export default function StateTutorial() {

	const code = (text: string) => <code className="inline">{text}</code>;
	const file = (text: string) => <i>{text}</i>;

	return (
		<div>
			<h1>Adding State</h1>
			<p>
				Now that we've split our app into two components, we want a way to decide which page to render.
				For that, we'll be adding something called "state".
			</p>
			<p>
				Essentially, state is a collection of variables that stores the internal status of a component.
				For example, we might want to store the number of times a user has clicked a button, or keep
				track of the text they entered in a text box.
			</p>

			<h3>Initializing state</h3>
			<p>
				To add state to a component, we need to set an initial value in the components <i>constructor</i>.
				A constructor is a function that is run each time we create a new instance of a component.
				Let's add some basic state to the {code('App')} component:
			</p>
			<SyntaxHighlighter
				language="javascript"
				showLineNumbers={true}
				style={mystyle}
			>
				{appWithState}
			</SyntaxHighlighter>

			<p>
				Let's explain what's going on here. Since our {code('App')} component is a child
				of {code('React.Component')}, we first need to call the parent constructor. We do this by
				calling {code('super')}. Then we set the state. This line might seem strange if you're
				new to JavaScript. What's happening is we're creating a new object, and that object has
				one property: "isLandingPage", with a value of {code('true')}.
			</p>

			<h3>Using state</h3>
			<p>
				We want to use {code('isLandingPage')} to determine which page to render. This is where
				we start writing actual code! In the {code('App')} component:
			</p>

			<SyntaxHighlighter
				language="javascript"
				showLineNumbers={true}
				style={mystyle}
			>
				{renderConditionally}
			</SyntaxHighlighter>

			<p>
				First, we create a variable to hold the elements we want to render. This is a very important feature
				that is used a lot in React. Notice that we're accessing the component's state to decide which
				component gets assigned to the variable. Then, in our return statement, we do something weird.
				In React, when we use curly braces in the HTML, it allows us to insert code. In this case, we're
				using curly braces to display the value of the {code('page')} variable (which should be our
				component)!
			</p>
			<p>
				If you save the file and reload the page, you should see that only the landing page is displayed.
				Now we just need a way to switch to the data collection page.
			</p>

			<h3>Updating state</h3>
		</div>
	);
}
