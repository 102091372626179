import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { stackoverflowLight as mystyle } from 'react-syntax-highlighter/dist/esm/styles/hljs';

const boilerplate: string =
`import './DataCollectionPage.css';
import React from 'react';

class DataCollectionPage extends React.Component {
  render() {
    return (
      // Our HTML will go here!
    );
  }
}

export default DataCollectionPage;`

const extractedComponents: string =
`import './App.css';
import React from 'react';
import DataCollectionPage from './components/data-collection-page/DataCollectionPage';
import LandingPage from './components/landing-page/LandingPage';

class App extends React.Component {

  render() {
    return (
      <div>
        <DataCollectionPage />
        <LandingPage />
      </div>
    );
  }
}

export default App;`

export default function ExtractComponentTutorial() {

	const code = (text: string) => <code className="inline">{text}</code>;
	const file = (text: string) => <i>{text}</i>;

	return (
		<div>
			<h1>Extracting Components</h1>
			<p>
				We now have the basic layout for our content, but we'd like to split things out into multiple components.
				This will make is easy to switch between pages in the app.
			</p>

			<p>
				<b>Note:</b> for our codebase, we want to follow a consistent naming scheme. When we create a new folder, we will
				use all lowercase and dashes: {file('example-folder-name')}. However, for files, we will use
				camel-case: {file('ExampleFileName.jsx')}.
			</p>

			<h3>Add some folders</h3>
			<p>
				First, inside our {file('src')} folder, let's create a new folder called {file('components')}.
				Inside this components folder, create two more folders: one called {file('landing-page')}, and
				another called {file('data-collection-page')}. These last two folders are where we will put
				our new components.
			</p>

			<h3>Add basic code</h3>
			<p>
				Now we'll actually create our components. In the {file('data-collection-page')} folder, create two
				new files: {file('DataCollectionPage.css')} and {file('DataCollectionPage.jsx')}.
			</p>
			<p>
				Every component contains some "boilerplate" code to mark the class as a React component. Let's add the
				following boilerplate code into {file('DataCollectionPage.jsx')}
			</p>
			<SyntaxHighlighter
				language="javascript"
				showLineNumbers={true}
				style={mystyle}
			>
				{boilerplate}
			</SyntaxHighlighter>
			<p>
				Remember, the {code('render')} function returns the HTML we want to display inside this component.
				We're going to move the HTML for the data collection page into this component. Go ahead and copy the
				corresponding HTML from the {code('App')} component into this one.
			</p>

			<h3>Rinse and repeat</h3>
			<p>
				Now we want to extract the landing page code into its own component, just like we did for the data
				collection page. Go ahead and try this on your own.
			</p>

			<h3>Render our new components</h3>
			<p>
				Now we're going to finish our code cleanup. Go into the {code('App')} component again, and remove
				ALL of the HTML from the render function. Instead, we want to display the new components we've created

			</p>
			<SyntaxHighlighter
				language="javascript"
				showLineNumbers={true}
				style={mystyle}
			>
				{extractedComponents}
			</SyntaxHighlighter>
			<p>
				There are a couple things that stand out here. First, we added imports at the top. These allow use to use
				the component we created in this file. Second, we added some new elements to the {code('render')} function.
				These are actually the components we created! If you save all your files and re-render the app, everything
				should look the same as it did before.
			</p>
			<p>
				Why did we go through all this work, just to make it look the same? Well, our code is now more readable
				and easier to maintain. If I wanted to modify the landing page, it's obvious I should go into the file
				called {file('LandingPage.jsx')}. These changes also set us up for the next step: only rendering
				one page at a time.
			</p>
		</div>
	);
}
